body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#brand {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10000;
  cursor: pointer;
}

#container {
  display: flex;
  flex-direction: row;
  max-height: 100vh;
}

#logo {
  float: left;
  font-weight: 700;
  font-family: 'Circular', sans-serif;
  /*background-color: #2ecc71;*/
  background-color: #fff;
  /*color: #fff;*/
  padding: 10px;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 5px;
  /*border-color: white;*/
  /*border-style: solid;*/
  /*border-width: 4px;*/
}
#map {
	width: 100%;
	height: 100vh;
}

#map-container {
	width: 60%;
}

.mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right{
    display: none;
}

#reset {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1;
}

.home-btn {
	font-size: 1em;
	width: 70px;
	height: 30px;
	font-weight: 700;
	border-radius: 500px;
	cursor: pointer;
	background-color: white;
	text-align: center;
	padding-top: 10px;
}
#sidebar {
	width: 40%;
	max-height: 100vh;
  	color: black;
  	float: right;
  	padding: 20px 40px;
  	overflow-y: scroll;
  	display: flex;
  	flex-direction: column;
  	justify-content: space-between;
}

.content {
	min-height: 80%;
}

.footer {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.footer > div {
	margin: 0px 15px;
}

.station-link {
	color: blue;
	text-decoration: underline;
	cursor: pointer;
}

.footer:hover .love {
   color: red;
}
