#map {
	width: 100%;
	height: 100vh;
}

#map-container {
	width: 60%;
}

.mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right{
    display: none;
}

#reset {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1;
}

.home-btn {
	font-size: 1em;
	width: 70px;
	height: 30px;
	font-weight: 700;
	border-radius: 500px;
	cursor: pointer;
	background-color: white;
	text-align: center;
	padding-top: 10px;
}