#brand {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10000;
  cursor: pointer;
}

#container {
  display: flex;
  flex-direction: row;
  max-height: 100vh;
}

#logo {
  float: left;
  font-weight: 700;
  font-family: 'Circular', sans-serif;
  /*background-color: #2ecc71;*/
  background-color: #fff;
  /*color: #fff;*/
  padding: 10px;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 5px;
  /*border-color: white;*/
  /*border-style: solid;*/
  /*border-width: 4px;*/
}